import React from "react"
import Layout from "@/components/layout"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import logoBertPro from '@/images/lp/logotipo-bert.svg'

const LeadsPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Leads">
        <div style={{ background: '#b2e5ff', textAlign: 'center' }}>
          <img src={logoBertPro} style={{ marginTop: 40 }} />
          <iframe src="https://forms.monday.com/forms/embed/658e9890a15c117d2cee6763d6fbfed5?r=use1" width="100%" height="2000" style={{ border: 'none' }} />
        </div>
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default LeadsPage;

